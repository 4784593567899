import React, { useState, useEffect } from "react"
import { useGuestApi } from "../../hooks/useApi"
import MainContent from "../Layout/MainContent"
import withGuestLayout from "../../hoc/withGuestLayout"
import Title from "../Text/Title"
import { getTitle } from "../../helpers/Publications"
import BodyContent from "../Text/BodyContent"
import { returnLayoutSection } from "../../helpers/Layout"

function Contact() {
  const { getContactPublication } = useGuestApi();
  const [content, setContent] = useState(null);

  useEffect(() => {
    getContactPublication().then(response => {
      setContent(response);
    })
  }, [getContactPublication])

  if(!content) {
    return null;
  }

  return (
    <MainContent {...content} headerWidth={960} headerHeight={312}>
      <Title>{getTitle(content.title)}</Title>
      <BodyContent>
      {content.layout
        ? Array.isArray(content.layout.items)
          ? content.layout.items.map((item, i) => {
              return returnLayoutSection(item, i)
            })
          : null
        : null
      }
      </BodyContent>
    </MainContent>
  )
}

export default withGuestLayout(Contact, {
  page: "Contact",
})
