/* import React from "react"
import Container from "../components/Layout/Container"
import MainContent from "../components/Layout/MainContent"
import BodyContent from "../components/Text/BodyContent"
import Title from "../components/Text/Title"
import { CONTACT_SOCIALS } from "../fixtures/SocialsData"
import hero from "../resources/images/hero-default.jpg"
import "./styles/Contact.scss"

function contact() {
  const { general, pers, beachFestival } = CONTACT_SOCIALS

  return (
    <Container page="Contact" shareImage={hero}>
      <div className="contact">
        <MainContent imageInternal={hero}>
          <Title>Contact</Title>
          <BodyContent>
            <p>
              <strong>Play Nostalgie</strong>
              <br />
              Katwilgweg 2<br />
              2050 Antwerpen
              <br />
              BTW BE 0890.243.036
            </p>
            <p>
              <strong>Kantoor:</strong> 03/210 04 40
              <br />
              <strong>SMS:</strong> 6036{" "}
              <small>(0,50 EUR/SMS verzonden/ontvangen)</small>
            </p>
            <p>
            Je kan met je ideeën, vragen en opmerkingen ook steeds via mail bij ons terecht op <a href={general.url}>{general.title}</a>.
            </p>
            <p>
              <strong>Pers & Communicatie</strong>
              <br />
              Voor interviewaanvragen, persfoto's en meer persgerelateerde vragen, kun je mailen met <a href={pers.url}>{pers.title}</a>.
            </p>
            <p>
              <strong>Beach Festival </strong>
              <br />
              Voor vragen over het Beach Festival kan je mailen naar <a href={beachFestival.url}>{beachFestival.title}</a>.
            </p>
          </BodyContent>
        </MainContent>
      </div>
    </Container>
  )
}

export default contact
 */

import React from "react"
import { Router } from "@reach/router"
import Contact from "../components/Contact/Contact";

const ContactPage = () => {
  return (
    <Router>
      <Contact path="contact" />
    </Router>
  )
}

export default ContactPage
